var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from "vue-property-decorator";
import { Button } from 'element-ui';
import layoutCase from '$layout/layout-case';
import cashData from '../data/case';
import introduceData from "../data/introduce";
import vBackground from '$components/background';
import imageOption from '../option/image';
import viewFooter from '$views/footer';
import VImage from "$components/v-image/lib/main.vue";
import ScrollView from "$components/scroll-view/src/main.vue";
import { vAnimate, vAnimateItem, vAnimateTiming } from '$components/index';
import require_image from "$utils/images/require_image";
let default_1 = class extends Vue {
    constructor() {
        super(...arguments);
        // 商城类App
        this.cashData = cashData;
        // 介绍
        this.introduceData = introduceData;
        // 介绍背景
        this.introduceBackground = require_image('images/background.png', imageOption);
    }
    scroll(scroll) {
        return this.$refs.animate && this.$refs.animate.animate(scroll);
    }
};
default_1 = __decorate([
    Component({
        components: {
            ScrollView,
            VImage,
            [Button.name]: Button,
            layoutCase,
            viewFooter,
            vBackground,
            vAnimate,
            vAnimateItem,
            vAnimateTiming
        }
    })
], default_1);
export default default_1;
