import imageOption from '../option/image';
import require_image from "$utils/images/require_image";
export default [
    {
        label: 'APP软件开发',
        introduce: '专属定制 一对一解决方案',
        icon: require_image('images/introduce/1.png', imageOption)
    },
    {
        label: 'H5/微信平台开发',
        introduce: '专属定制 一对一解决方案',
        icon: require_image('images/introduce/2.png', imageOption)
    },
    {
        label: '网站开发',
        introduce: '专属定制 一对一解决方案',
        icon: require_image('images/introduce/3.png', imageOption)
    },
    {
        label: '管理软件开发',
        introduce: '专属定制 一对一解决方案',
        icon: require_image('images/introduce/4.png', imageOption)
    }
];
